export const allowedLocals = [
  {
    id: 'corpo',
    value: 'corpo',
    label: 'Corpo',
  },
  {
    id: 'cabecalho',
    value: 'cabecalho',
    label: 'Cabeçalho',
  },
];
